.front-page-information {
    display: flex;
    flex-direction: column;
}

.part {
    display: flex;
    flex-direction: row;
    height: 30rem;
    padding: .5rem;
    align-items: center;
    justify-content: center;
}

.part-picture {
    height: 60%;
    /* margin: 0 50px; */
    border-radius: 15px;
    /* box-shadow: 0 3px 1em black; */
    max-height: 20rem;
}

.part-heading {
    height: 15%;
    font-size: 25px;
    font-weight: bold;
    width: 100%;
}

.part-description {
    height: 25%;
}

.other-part {
    background-color: rgba(255, 69, 0, .9);
}

.first-part {
    background-color: orange;
}

.last-part {
    background-color: orange;
}

.part-info {
    width: 30rem;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info-container {
    display: flex;
    background-color: rgba(0,0,0,.3);
    border-radius: 15px;
    max-height: 20rem;
}

.last-part-parts {
    display: flex;
    flex-direction: column;
    height: 35rem;
}

.last-part-info-container {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.last-part-description {
    max-width: 40rem;
    text-align: center;
}

.last-part-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.download-container {
    display: flex;
    height: 15rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 69, 0, .9);
}

.ready {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
}
